import React from "react";
import styled, { css } from "styled-components";
import Image from "../../ImageQuerys/HeroImages";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { linkBlanc, linkRouge, palette } from "../../styles/styles";
import { Link } from "gatsby";

const HeroSection = (props) => {
  const { className, data, nameSpace } = props;
  const { t } = useTranslation([nameSpace]);

  return (
    <Image className="bgHero" imageName={data.imageName}>
      <section className={className}>
        <h1>{t("heroTitle")}</h1>
        <p>{t("heroDesc")}</p>
        {data.hasButtons ? (
          <div className="buttons">
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
              target="_blank"
              rel="noreferrer"
              className="first"
            >
              {t("heroFirstButton")}
            </a>
            <Link className="second" to="/contact">
              {t("heroSecondButton")}
            </Link>
          </div>
        ) : null}
      </section>
    </Image>
  );
};

export default styled(HeroSection)`
  padding: 0 16%;
  color: ${palette.whiteWhite};
  ${({ withGradient }) =>
    withGradient &&
    css`
      background: transparent
        linear-gradient(359deg, #23232367 0%, #6c6c6c03 100%) 0% 0% no-repeat
        padding-box;
    `}
  @media (max-height: 899px) {
    min-height: 70vh;
  }
  @media (min-height: 900px) {
    min-height: 76rem;
  }
  display: grid;
  grid-template-rows: repeat(3, max-content);
  justify-items: center;
  align-content: center;
  @media (max-width: 600px) {
    height: fit-content;
    padding: 4rem 10%;
  }
  .bgHero {
    z-index: -1;
    position: absolute;
    max-height: 70vh;
  }
  h1 {
    font-size: 7.8rem;
    color: ${palette.whiteWhite};
    text-align: center;
    font-weight: 400;
    margin-top: 5rem;
    margin-bottom: 3rem;
    @media (max-width: 600px) {
      font-size: 4.8rem;
    }
  }
  p {
    font-size: 2.4rem;
    max-width: 764px;
    width: 100%;
    text-align: center;
    line-height: 2.8rem;
    margin-bottom: 6rem;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    grid-column-gap: 5rem;
    margin-bottom: 5rem;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 2rem;
    }
    font-size: 1.6rem;
    font-family: SofiaProRegularAz, sans-serif;
    .first {
      ${linkRouge};
      font-size: 1.8rem;
    }
    .second {
      ${linkBlanc};
      font-size: 1.8rem;
    }
  }
`;
