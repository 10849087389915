import React from "react";
import Layout from "../components/reusable/Layout";
import HeroSection from "../components/reusable/HeroSection";
import First from "../components/auto-hunt/fisrt";
import Multi from "../components/auto-hunt/multi";
import Final from "../components/auto-hunt/Final";
import { graphql } from "gatsby";
import Seo from "../components/reusable/Seo";

const PreScreening = () => {
  return (
    <>
      <Seo
        title="Pre Screening"
        canonical="https://www.lapieza.io/pre-screening"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <HeroSection
          data={{
            imageName: "heroHunt.png",
            hasButtons: true,
          }}
          nameSpace="autoHunt"
          withGradient
        />
        <First />
        <Multi
          data={{
            type: 0,
            imageName: "exec0.png",
            red: "multiFirstRed",
            h3: "multiFirstTitle",
            p: "multiFirstDesc",
          }}
        />
        <Multi
          data={{
            type: 1,
            imageName: "autohunt2.png",
            red: "multiSecondRed",
            h3: "multiSecondTitle",
            p: "multiSecondDesc",
          }}
        />
        <Multi
          data={{
            type: 0,
            imageName: "autohunt3.png",
            red: "multiThirdRed",
            h3: "multiThirdTitle",
            p: "multiThirdDesc",
          }}
        />
        <Final />
      </Layout>
    </>
  );
};

export default PreScreening;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
